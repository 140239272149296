<template>
<aheader />
<section class="section">
  <div class="columns">
    <div class="column is-8 is-offset-2">
      Step
      <span class="icon has-text-success is-big">
        <i class="fa-solid fa-2 fa-lg"></i>
      </span>
      <progress class="progress is-success" value="40" max="100"></progress>
    </div>
  </div>
  <div class="error-message notification is-danger is-light" v-show="errorMsg != ''" v-html="errorMsg"></div>
  <section class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="box">
          <div class="title is-4">
            お客さま情報の入力
          </div>
          <div class="columns">
            <div class="column is-offset-2">
              <div class="has-text-left is-size-5">
                お名前
              </div>
            </div>
          </div>
          <div class="field">
            <div class="columns">
              <div class="column is-4 is-offset-2">
                <div class="control">
                  <input class="input" type="name" placeholder="姓" v-model="$store.state.order_customer.familyName">
                </div>
              </div>
              <div class="column is-4">
                <div class="control">
                  <input class="input" type="name" placeholder="名" v-model="$store.state.order_customer.firstName">
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="columns">
              <div class="column is-4 is-offset-2">
                <div class="control">
                  <input class="input" type="name" placeholder="セイ" v-model="$store.state.order_customer.kanaFamily">
                </div>
              </div>
              <div class="column is-4">
                <div class="control">
                  <input class="input" type="name" placeholder="メイ" v-model="$store.state.order_customer.kanaFirst">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2">
              <div class="has-text-left is-size-5">
                生年月日
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="select">
                <select v-model="$store.state.order_customer.birthYear">
                  <option v-for="year, key in years" :key='key'>{{year}}年</option>
                </select>
              </div>
              <div class="select">
                <select v-model="$store.state.order_customer.birthMonth">
                  <option v-for="month, key in months" :key='key'>{{month}}月</option>
                </select>
              </div>
              <div class="select">
                <select v-model="$store.state.order_customer.birthDay">
                  <option v-for="day, key in days" :key='key'>{{day}}日</option>
                </select>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2">
              <div class="has-text-left is-size-5">
                性別
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="control">
                <label class="radio">
                  <input type="radio" name="answer" value="男性" v-model="$store.state.order_customer.picked">
                  男性
                </label>
                <label class="radio">
                  <input type="radio" name="answer" value="女性" v-model="$store.state.order_customer.picked">
                  女性
                </label>
                <label class="radio">
                  <input type="radio" name="answer" value="指定しない" v-model="$store.state.order_customer.picked">
                  指定しない
                </label>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2">
              <div class="has-text-left is-size-5">
                お届け先住所
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="has-text-left m-1">
                郵便番号
              </div>
              <div class="control">
                <input class="input" type="email" placeholder="半角数字" v-model="$store.state.order_customer.postCode">
              </div>
              <div class="has-text-left mt-3">
                ご住所
              </div>
              <div class="control">
                <input class="input" type="email" placeholder="住所を番地まで含めて記入してください。" v-model="$store.state.order_customer.address">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2">
              <div class="has-text-left is-size-5">
                電話番号
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="control has-icons-left">
                <input class="input" type="phone" placeholder="電話番号" v-model="$store.state.order_customer.tel">
                <span class="icon is-small is-left">
                  <i class="fas fa-phone"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-2">
              <div class="has-text-left is-size-5">
                メールアドレス
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-8 is-offset-2">
              <div class="control has-icons-left">
                <input class="input" type="email" placeholder="メールアドレス" v-model="$store.state.order_customer.eMail">
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="columns">
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('cart')">
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span>
          前に戻る
        </span>
      </button>
    </div>
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="goNext()">
        <span>
          次にすすむ
        </span>
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </div>
</section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      errorMsg:"",
      years: [2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986,
        1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966,
        1965, 1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953, 1952, 1951, 1950, 1949, 1948, 1947, 1946,
        1945, 1944, 1943, 1942, 1941, 1940, 1939, 1938, 1937, 1936, 1935, 1934, 1933, 1932, 1931, 1930, 1929, 1928, 1927, 1926,
        1925, 1924, 1923, 1922, 1921, 1920, 1919, 1918, 1917, 1916, 1915, 1914, 1913, 1912, 1911, 1910, 1909, 1908, 1907, 1906, 1905
      ],
      months: ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: ["-", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],


    }
  },
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page);
    },
    addErrMsg(msg) {
      if (this.errorMsg != "") this.errorMsg += "<br/>";
      this.errorMsg = this.errorMsg + msg;
    },
    goNext() {
      this.errorMsg = "";
      if (this.$store.state.order_customer.familyName == "")  this.addErrMsg("姓（漢字）を入力してください。");
      if (this.$store.state.order_customer.firstName == "")  this.addErrMsg("名（漢字）を入力してください。");
      if (this.$store.state.order_customer.kanaFamily == "") this.addErrMsg("姓（カタカナ）を入力してください。");
      if (this.$store.state.order_customer.kanaFirst == "") this.addErrMsg("名（カタカナ）を入力してください。");
      if (this.$store.state.order_customer.birthYear == "") this.addErrMsg("生年月日（年）を選んでください。");
      if (this.$store.state.order_customer.birthMonth == "") this.addErrMsg("生年月日（月）を選んでください。");
      if (this.$store.state.order_customer.birthDay == "") this.addErrMsg("生年月日（日）を選んでください。");
      if (this.$store.state.order_customer.picked == "") this.addErrMsg("性別を選んでください。");
      if (this.$store.state.order_customer.postCode == "") this.addErrMsg("郵便番号を入力してください。");
      if (this.$store.state.order_customer.address == "") this.addErrMsg("住所を入力してください。");
      if (this.$store.state.order_customer.tel == "") this.addErrMsg("電話番号を入力してください。");
      if (this.$store.state.order_customer.eMail == "") this.addErrMsg("メールアドレスを入力してください。");
      if (this.errorMsg == "") {
        this.$router.push('order_confirm');
      } else {
        this.scrollTop();
      }
    }
  }
}
</script>
